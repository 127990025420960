@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    height: 100%;
}

.innerAspect {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 175.7%;
    border-radius: 2rem;

    .flushTop & {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .flushBottom & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .theme--makers-reserve & {
        padding-bottom: 100%;
        border-radius: 0;
    }
}

.aspectBox {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.innerContent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .theme--makers-reserve & {
        position: relative;
    }
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inlineImage {
    width: calc(100% + calc(var(--block-padding-side) * 2));
    max-width: none;
    margin: 0 0 0 calc(var(--block-padding-side) * -1);
}

.block {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9.1rem 2.4rem;
    padding: var(--block-padding);
    border: 0;
    color: var(--colors-cream);
    text-align: center;

    .theme--makers-reserve & {
        background-color: var(--colors-cream);
    }
}

.eyebrow {
    .theme--makers-reserve & {
        // Avoid widow
        max-width: 31rem;
    }
}

.richCopyWrapper {
    margin-top: 1.4rem;

    .theme--makers-reserve & {
        color: var(--colors-makersReserveBlack);
    }
}

.content {
    display: flex;
    max-width: 40rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .theme--makers-reserve & {
        max-width: 35rem;
    }
}

.cta {
    margin-top: 3.3rem;
}

.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100vh;
    transform: translateY(100%);
    transition: transform $easeInCubic $slow;

    &.isReveal {
        transform: translateY(0%);
        transition: transform $easeOutCubic $slow;
    }
}

.overlayCloseButton {
    opacity: 0;
    transition: opacity $easeOutCubic $fast;

    &.isReveal {
        opacity: 1;
        transition: opacity $easeOutCubic $slow $slow;
    }
}

@include medium {
    .root {
        .innerContent {
            position: absolute;
            flex-direction: row;
        }

        .innerAspect {
            padding-bottom: 50%;
        }

        .aspectBox {
            height: 100%;
            padding: 0;
        }

        .block {
            width: 50%;
            height: 100%;
            padding: 0;
        }

        .cta {
            margin-top: 4.3rem;
        }

        .content {
            max-width: 60rem;
        }

        &.theme--makers-reserve {
            .eyebrow {
                max-width: none;
            }

            .content {
                max-width: 50rem;
            }

            .innerContent {
                position: absolute;
            }

            .innerAspect {
                left: 50%;
                width: 50%;
                padding-bottom: 50%;
            }
        }
    }
}
