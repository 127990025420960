@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    height: 100%;
    background-color: var(--background-color);

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .flushBottom & {
        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);
    }

    .flushTop.flushBottom & {
        border-radius: 0;
    }

    .theme--makers-reserve & {
        border-radius: 0 !important;
        background-color: #000;
    }
}

.innerAspect {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100%;
}

.aspectBox {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem 0;

    > div {
        width: 100%;
    }
}

.innerContent {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3.6rem 5.7rem;
    border: 0;
    color: var(--colors-cream);
}

.eyebrow {
    max-width: 31rem;
}

.copy {
    margin-top: 1.4rem;
    text-align: center;

    .listItem & {
        margin: 0 0 1rem;
        text-align: left;
    }

    .isDark & {
        color: var(--colors-blue);
    }
}

.list {
    margin-top: 1.4rem;
}

.listItem::marker {
    .isDark & {
        color: var(--colors-blue);
    }
}

.content {
    display: flex;
    max-width: 35rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100vh;
    transform: translateY(100%);
    transition: transform $easeInCubic $slow;

    &.isReveal {
        transform: translateY(0%);
        transition: transform $easeOutCubic $slow;
    }
}

.overlayCloseButton {
    opacity: 0;
    transition: opacity $easeOutCubic $fast;

    &.isReveal {
        opacity: 1;
        transition: opacity $easeOutCubic $slow $slow;
    }
}

@include medium {
    .root {
        .eyebrow {
            max-width: none;
        }

        .content {
            max-width: 43rem;
        }
    }
}

@include large {
    .root {
        .innerContent {
            position: absolute;
            flex-direction: row;
        }

        .innerAspect {
            left: 50%;
            width: 50%;
            padding-bottom: 50%;
        }

        .aspectBox {
            height: 100%;
        }

        .block {
            width: 50%;
            height: 100%;
            padding: 0;
        }

        .content {
            max-width: 43rem;
        }
    }
}
